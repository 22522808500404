export default {
    setGames: ( state, payload ) => {
        state.games = payload;
    },
    setGameNames: ( state, payload ) => {
        state.gameNames = payload;
    },
    setArchive: ( state, payload ) => {
        state.archive = payload;
    },
    setSingleGame: ( state, payload ) => {
        state.singleGame = payload;
    },
    setStock: ( state, payload ) => {
        state.stock = payload;
    },
    setIGVGames: ( state, payload ) => {
        state.IGVGames = payload;
    }
}