import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
	{
		path: '/',
		name: 'dashboard',
		components: {
			root: () => import('@/views/TheDashboard'),
		},
		meta: {
			title: 'SDA :: Home',
		},
		children:
			[
				{
					path: 'game/create',
					name: 'game_create',
					components: {
						game_create: () => import('@/views/Game/AddGame'),
					},
					meta: {
						title: 'SDA :: Create Game',
					},
				},
				{
					path: 'game/add-accounts/:id',
					name: 'game_add_accounts',
					components: {
						game_add_accounts: () => import('@/views/Account/AddAccounts'),
					},
					meta: {
						title: 'SDA :: Add Account(s)',
					},
				},
				{
					path: 'game/edit/:id',
					name: 'game_edit',
					components: {
						game_edit: () => import('@/views/Game/EditGame'),
					},
					meta: {
						title: 'SDA :: Edit Game',
					},
				}
			]
	},
	{
		path: '/game/single/:id',
		name: 'single_game',
		components: {
			root: () => import('@/views/Game/TheSingleGame'),
		},
		meta: {
			title: 'SDA :: Game Details',
		},
		children:
		[
			{
				path: 'edit/:accountId',
				name: 'account_edit',
				components: {
					account_edit: () => import('@/views/Account/EditAccount'),
				},
				meta: {
					title: 'SDA :: Edit Account',
				},
			},
			{
				path: 'add-accounts',
				name: 'single_game_add_accounts',
				components: {
					single_game_add_accounts: () => import('@/views/Account/AddAccounts'),
				},
				meta: {
					title: 'SDA :: Add Account(s)',
				},
			},
		]
	},
	{
		path: '/orders',
		name: 'orders_archive',
		components: {
			root: () => import('@/views/Order/MyOrders'),
		},
		meta: {
			title: 'SDA :: Orders',
		},
		children: [
			{
				path: 'replace/:id',
				name: 'account_replace',
				components: {
					account_replace: () => import('@/views/Order/ReplaceAccount'),
				},
				meta: {
					title: 'SDA :: Send A Replacement',
				},
			},
			{
				path: 'view/:accountId',
				name: 'view_account',
				components: {
					view_account: () => import('@/views/Account/ViewAccount'),
				}
			},
			{
				path: 'create/',
				name: 'create_order',
				components: {
					create_order: () => import('@/views/Order/CreateOrder'),
				}
			},
		]
	},
	{
		path: '/support',
		name: 'support_page',
		components: {
			root: () => import('@/views/Support'),
		},
		meta: {
			title: 'SDA :: Customer Service',
		},
	},
	{
		path: '/stock',
		name: 'my_stock',
		components: {
			root: () => import('@/views/Game/MyStock'),
		},
		meta: {
			title: 'SDA :: Stock',
		},
	},
	{
		path: '/login',
		name: 'login',
		components: {
			root: () => import('@/views/Login'),
		},
		meta: {
			title: 'SDA :: Login',
		},
	},
	{
		path: '/settings',
		name: 'settings',
		components: {
			root: () => import('@/views/Settings'),
		},
		meta: {
			title: 'SDA :: Settings',
		},
	},
	{
		path: '/eldorado-offers',
		name: 'eldorado_offers',
		components: {
			root: () => import('@/views/EldoradoOfferManager/EldoradoOffers'),
		},
		meta: {
			title: 'SDA :: Eldorado Offers',
		},
		children:
		[
			{
				path: 'edit/:eldoradoOfferId',
				name: 'eldorado_offer_edit',
				components: {
					eldorado_offer_edit: () => import('@/views/EldoradoOfferManager/EditEldoradoOffer'),
				},
				meta: {
					title: 'SDA :: Edit Eldorado Offer',
				},
			},
		]
	},
	{
		path: '/zeusx-offers',
		name: 'zeusx_offers',
		components: {
			root: () => import('@/views/ZeusxOfferManager/ZeusxOffers'),
		},
		meta: {
			title: 'SDA :: Zeusx Offers',
		},
		children:
		[
			{
				path: 'edit/:zeusxOfferId',
				name: 'zeusx_offer_edit',
				components: {
					zeusx_offer_edit: () => import('@/views/ZeusxOfferManager/EditZeusxOffer'),
				},
				meta: {
					title: 'SDA :: Edit Zeusx Offer',
				},
			},
		]
	},
	{
		path: '/g2g-offers',
		name: 'g2g_offers',
		components: {
			root: () => import('@/views/G2GOfferManager/G2GOffers'),
		},
		meta: {
			title: 'SDA :: G2G Offers',
		},
		children:
		[
			{
				path: 'edit/:g2gOfferId',
				name: 'g2g_offer_edit',
				components: {
					g2g_offer_edit: () => import('@/views/G2GOfferManager/EditG2GOffer'),
				},
				meta: {
					title: 'SDA :: Edit G2G Offer',
				},
			},
		]
	},
	{
		path: '/users',
		name: 'my_users',
		components: {
			root: () => import('@/views/User/MyUsers'),
		},
		meta: {
			title: 'SDA :: Users',
		},
		children: [
			{
				path: 'create',
				name: 'add_new_user',
				components: {
					add_new_user: () => import('@/views/User/AddUser'),
				},
				meta: {
					title: 'SDA :: Add New User',
				},
			},
			{
				path: 'edit/:id',
				name: 'edit_user',
				components: {
					edit_user: () => import('@/views/User/EditUser'),
				},
				meta: {
					title: 'SDA :: Edit User',
				},
			},
		]
	},
	{
		path: '/logs',
		name: 'my_logs',
		components: {
			root: () => import('@/views/Logs'),
		},
		meta: {
			title: 'SDA :: Logs',
		},
	},
	{
		path: '/eldorado-boosting-manager',
		name: 'eldorado_boosting_manager',
		components: {
			root: () => import('@/views/EldoradoBoostingManager/EldoradoBoostingManager'),
		},
		meta: {
			title: 'SDA :: Eldorado Boosting Manager',
		},
		children: [{
			path: 'edit-game/:id',
			name: 'edit_boosting_subscribed_game',
			components: {
				edit_boosting_subscribed_game: () => import('@/views/EldoradoBoostingManager/EditEldoradoBoostingSubscribedGame'),
			},
			meta: {
				title: 'SDA :: Edit Boosting Subscribed Game',
			},
		}]
	},
	{
		path: '/accounts',
		name: 'accounts',
		components: {
			root: () => import('@/views/Account/MyAccounts'),
		},
		meta: {
			title: 'SDA :: Stock :: Accounts',
		},
		children: [
			{
				path: 'edit/:accountId',
				name: 'accounts_page_single_edit',
				components: {
					accounts_page_single_edit: () => import('@/views/Account/EditAccount'),
				},
				meta: {
					title: 'SDA :: Stock :: Edit Account',
				},
			}	
		]
	}
];

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes
});

const DEFAULT_TITLE = 'SDA :: Home';
router.afterEach((to) => {
    // Use next tick to handle router history correctly
    // see: https://github.com/vuejs/vue-router/issues/914#issuecomment-384477609
    Vue.nextTick(() => {
		document.title = to.meta.title || DEFAULT_TITLE;
	});
});

export default router;
