import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

Vue.config.productionTip = false

import {
  BootstrapVue,
} from 'bootstrap-vue'
Vue.use(BootstrapVue)

import VueFormulate from '@braid/vue-formulate'
Vue.use(VueFormulate);

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import VueGoodTablePlugin from 'vue-good-table';

// import the styles 
import 'vue-good-table/dist/vue-good-table.css'

Vue.use(VueGoodTablePlugin);

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
