'use strict'

import axios from "axios";

if( !axios.defaults.headers.common.Authorization) {
    axios.defaults.headers.common = {
        "Authorization": "Basic " + localStorage.getItem( 'authbearer' ),
        "Content-Type": "application/json"
    };
}

export default {
    getReceivedBoostingRequests: (state, data) => {
        return new Promise( done => {
            axios.get( `/api/eldorado-boosting-request/list/${data.page}/${data.perPage}/?s=${data.searchTerm}` )
                .then( res => {
                    done( res.data )
                } )
                .catch( err => done( err ) )
        } )
    },
    getGamesWithBoostingServices: (state, data) => {
        return new Promise( done => {
            axios.get( `/api/eldorado-boosting-request/list-game/${data.page}/${data.perPage}/?s=${data.searchTerm}` )
                .then( res => {
                    done( res.data )
                } )
                .catch( err => done( err ) )
        } )
    },
    getSingleEldoradoBoostingGame: (state, data) => {
        return new Promise( done => {
            axios.get( '/api/eldorado-boosting-request/single-game/' + data.id )
            .then( res => {
                done( res.data )
            } )
            .catch( err => done( err ) )
        } )
    },
    editEldoradoBoostingGame: (state, data) => {
        return new Promise( done => {
            axios.put( '/api/eldorado-boosting-request/edit-subscribed-game', data )
            .then( res => {
                done( res.data )
            } )
            .catch( err => done( err ) )
        } )
    },
    setEldoradoBoostingGameMeta: (state, data) => {
        return new Promise( done => {
            axios.put( '/api/eldorado-boosting-request/set-game-meta', data )
                .then( res => done( res.data ) )
                .catch( err => done( err ) )
        } )
    }
}