'use strict'

import axios from "axios";

if( !axios.defaults.headers.common.Authorization) {
    axios.defaults.headers.common = {
        "Authorization": "Basic " + localStorage.getItem( 'authbearer' ),
        "Content-Type": "application/json"
    };
}

export default {
    getLogs: (state, data ) => {
        return new Promise( done => {
            axios.get( `/api/logs/list/${data.worker}/${data.page}/${data.perPage}/?s=${data.searchTerm}` )
                .then( res => {
                    done( res.data )
                } )
                .catch( err => done( err ) )
        } )
    },
    deleteLog: (state, data) => {
        return new Promise( done => {
            axios.delete( '/api/logs/delete/' + data.id )
                .then( res => done( res.data ) )
                .catch( err => done( err ) )
        } )
    },
}