'use strict'

import axios from "axios";

if( !axios.defaults.headers.common.Authorization) {
    axios.defaults.headers.common = {
        "Authorization": "Basic " + localStorage.getItem( 'authbearer' ),
        "Content-Type": "application/json"
    };
}

export default {
    getAccounts: (state, data ) => {
        return new Promise( done => {
            axios.get( `/api/account/list/${data.game}/${data.page}/${data.perPage}/${data.eldorado.page}/${data.eldorado.perPage}/${data.pa.page}/${data.pa.perPage}/${data.igv.page}/${data.igv.perPage}/${data.csk.page}/${data.csk.perPage}/?s=${data.searchTerm}&eldorados=${data.eldorado.searchTerm}&pas=${data.pa.searchTerm}&igvs=${data.igv.searchTerm}&csks=${data.igv.searchTerm}`)
                .then( res => {
                    done( res.data )
                } )
                .catch( err => done( err ) )
        } )
    },
    getBulkAccounts: (state, data ) => {
        return new Promise( done => {
            axios.get( `/api/account/list-bulk/${data.page}/${data.perPage}/?s=${data.searchTerm}`)
                .then( res => {
                    done( res.data )
                } )
                .catch( err => done( err ) )
        } )
    },
    getAccount: (state, data) => {
        return new Promise( done => {
            axios.get( '/api/account/single/' + data.id )
                .then( res => {
                    done( res.data )
                } )
                .catch( err => done( err ) )
        } )
    },
    bumpPAOffers: (state, data) => {
        return new Promise( done => {
            axios.put( '/api/account/bump-pa', data )
                .then( res => done( res.data ) )
                .catch( err => done( err ) )
        } )
    },
    createAccounts: (state, data) => {
        return new Promise( done => {
            axios.post( '/api/account/create', data )
                .then( res => done( res.data ) )
                .catch( err => done( err ) )
        } )
    },
    editAccount: (state, data) => {
        return new Promise( done => {
            axios.put( '/api/account/edit', data )
                .then( res => done( res.data ) )
                .catch( err => done( err ) )
        } )
    },
    deleteAccount: (state, data) => {
        return new Promise( done => {
            axios.delete( '/api/account/delete/' + data.id )
                .then( res => done( res.data ) )
                .catch( err => done( err ) )
        } )
    },
    setMeta: (state, data) => {
        return new Promise( done => {
            axios.put( '/api/account/set-meta', data )
                .then( res => done( res.data ) )
                .catch( err => done( err ) )
        } )
    },
    changeAccountGame: (state, data) => {
        return new Promise( done => {
            axios.put( '/api/account/change-game', data )
                .then( res => done( res.data ) )
                .catch( err => done( err ) )
        } )
    },
}