export default {
    ADD_ALERT: ({commit, state}, data) => {
        data.id = Date.now();
        commit('addAlert', data);
        if (state.alerts.length) {
            setTimeout(() => {
                let removeMe;
                for (let i = 0; i < state.alerts.length; i++) {
                    if (data.id === state.alerts[i].id) {
                        removeMe = i;
                    }
                }
                commit('removeAlert', removeMe);
            }, 1000 * 10)
        }
    },
    REMOVE_ALERT: ({commit, state}, data) => {
        let removeMe;
        for (let i = 0; i < state.alerts.length; i++) {
            if (data.id === state.alerts[i].id) {
                removeMe = i;
            }
        }
        commit('removeAlert', removeMe);
    },
}