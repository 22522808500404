'use strict'

import axios from "axios";

if( !axios.defaults.headers.common.Authorization) {
    axios.defaults.headers.common = {
        "Authorization": "Basic " + localStorage.getItem( 'authbearer' ),
        "Content-Type": "application/json"
    };
}

export default {
    //getApeBoosters
    authenticate: ( state, data ) => {
        return new Promise( done => {
            axios.post( '/api/users/authenticate', data )
            .then( res => {
                done( res.data )
            } )
            .catch( err => {
                console.log( err );
                done( err )
            } )
        } )
    },
    getPermissions: ( { commit }, data ) => {
        return new Promise( (done, reject) => {
            axios.get( '/api/users/permissions', data )
                .then( res => {
                    if( res.data && res.data.success ){
                        commit( 'setPermissions', res.data.data );
                        done( res.data )
                    }
                    else {
                        reject( res )
                    }
                } )
                .catch( err => {
                    reject( err );
                } )
        } )
    },
    getUsers: ( ) => {
        return new Promise( done => {
            axios.get( '/api/users/list')
            .then( res => {
                done( res.data )
            } )
            .catch( err => done( err ) )
        } )
    },
    getUser: ( state, data ) => {
        return new Promise( done => {
            axios.get( '/api/users/single/' + data.id )
            .then( res => {
                done( res.data )
            } )
            .catch( err => done( err ) )
        } )
    },
    createUser: ( state, data ) => {
        return new Promise( done => {
            axios.post( '/api/users/create', data )
            .then( res => {
                done( res.data )
            } )
            .catch( err => {
                console.log( err );
                done( err )
            } )
        } )
    },
    editUser: ( state, data ) => {
        return new Promise( done => {
            axios.put( '/api/users/edit', data )
            .then( res => {
                done( res.data )
            } )
            .catch( err => {
                console.log( err );
                done( err )
            } )
        } )
    },
    deleteUser: ( state, data ) => {
        return new Promise( done => {
            axios.delete( '/api/users/delete/' + data.id )
            .then( res => {
                done( res.data )
            } )
            .catch( err => done( err ) )
        } )
    },
}