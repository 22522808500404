export default {
   setActualPlatforms: (state,payload) => {
      state.platforms = payload;
   },
   setWorkers: (state,payload) => {
      state.workers = payload;
   },
   setActiveSellers: (state,payload) => {
      state.sellers = payload;
   },
   setPermissions: (state,payload) => {
      state.permissions = payload;
   },
   setMenuLinks: (state, payload) => {
      state.staticMenuLinks = payload;
   }
}