'use strict'

import axios from "axios";

if( !axios.defaults.headers.common.Authorization) {
    axios.defaults.headers.common = {
        "Authorization": "Basic " + localStorage.getItem( 'authbearer' ),
        "Content-Type": "application/json"
    };
}

export default {
    getGamesList: (state) => {
        return new Promise( done => {
            axios.get( `/api/game/list` )
            .then(res => {
                if( res.data && res.data.success ){
                    state.commit( 'setGames', res.data.data );
                }
                done( res.data )
            } )
            .catch( err => done( err ) )
        } )
    },
    getGamesNamesList: (state,data) => {
        return new Promise( done => {
            axios.get( `/api/game/list-names/` + ( ( data && data.unique ) ? data.unique.toString() : 'false' ) )
            .then(res => {
                if( res.data && res.data.success ){
                    state.commit( 'setGameNames', res.data.data );
                }
                done( res.data )
            } )
            .catch( err => done( err ) )
        } )
    },
    getStock: (state) => {
        return new Promise( done => {
            axios.get( `/api/game/stock` )
            .then(res => {
                if( res.data && res.data.success ){
                    state.commit( 'setStock', res.data.data );
                }
                done( res.data )
            } )
            .catch( err => done( err ) )
        } )
    },
    getFaultyStockAmounts:() => {
        return new Promise( done => {
            axios.get( `/api/stock/faulty-amounts` )
            .then(res => {
                done( res.data )
            } )
            .catch( err => done( err ) )
        } )
    },
    getArchivedGamesList: (state) => {
        return new Promise( done => {
            axios.get( `/api/game/list-archive` )
            .then(res => {
                if( res.data && res.data.success ){
                    state.commit( 'setArchive', res.data.data );
                }
                done( res.data )
            } )
            .catch( err => done( err ) )
        } )
    },
    getSingleGame: (state, data) => {
        return new Promise( done => {
            axios.get( `/api/game/single/${data.id}` )
            .then(res => {
                if( res.data && res.data.success ){
                    state.commit( 'setSingleGame', res.data.data );
                }
                done( res.data )
            } )
            .catch( err => done( err ) )
        } )
    },
    ///api/game/get-igv-fields/:id
    getIgvFields: (state,data) => {
        return new Promise( done => {
            axios.get( `/api/game/get-igv-fields/${data.id}` )
            .then(res => {
                done( res.data )
            } )
            .catch( err => done( err ) )
        } )
    },
    ///api/game/get-igv-games/
    getIgvGames: (state) => {
        return new Promise( done => {
            if( state.state && state.state.workers && state.state.igvGames.length > 0 ) {
                done( state.state.igvGames );
                return;
            }

            axios.get( `/api/game/get-igv-games/` )
            .then(res => {
                if( res.data && res.data.success ){
                    state.commit( 'setIGVGames', res.data.data );
                }

                done( res.data )
            } )
            .catch( err => done( err ) )
        } )
    },
    archiveGame: (state,data) => {
        return new Promise( done => {
            axios.put( `/api/game/archive`, data )
            .then(res => {
                done( res.data )
            } )
            .catch( err => done( err ) )
        } )
    },
    restoreGame: (state,data) => {
        return new Promise( done => {
            axios.put( `/api/game/restore`, data )
            .then(res => {
                done( res.data )
            } )
            .catch( err => done( err ) )
        } )
    },
    createGame: (state, data) => {
        return new Promise( done => {
            axios.post( '/api/game/create', data )
                .then( res => done( res.data ) )
                .catch( err => done( err ) )
        } )
    },
    editGame: (state, data) => {
        return new Promise( done => {
            axios.put( '/api/game/edit', data )
                .then( res => done( res.data ) )
                .catch( err => done( err ) )
        } )
    },
    duplicateGame: (state, data) => {
        return new Promise( done => {
            axios.put( '/api/game/duplicate', data )
                .then( res => done( res.data ) )
                .catch( err => done( err ) )
        } )
    },
    setMeta: (state, data) => {
        return new Promise( done => {
            axios.put( '/api/game/set-meta', data )
                .then( res => done( res.data ) )
                .catch( err => done( err ) )
        } )
    },
    activateGame: (state, data) => {
        return new Promise( done => {
            axios.put( '/api/game/activate', data )
                .then( res => done( res.data ) )
                .catch( err => done( err ) )
        } )
    },
    deactivateGame: (state, data) => {
        return new Promise( done => {
            axios.put( '/api/game/deactivate', data )
                .then( res => done( res.data ) )
                .catch( err => done( err ) )
        } )
    },
    deleteGame: (state, data) => {
        return new Promise( done => {
            axios.delete( '/api/game/delete/' + data.id )
                .then( res => done( res.data ) )
                .catch( err => done( err ) )
        } )
    }
}