'use strict'

import axios from "axios";

if( !axios.defaults.headers.common.Authorization) {
    axios.defaults.headers.common = {
        "Authorization": "Basic " + localStorage.getItem( 'authbearer' ),
        "Content-Type": "application/json"
    };
}

export default {
    getProfit: (state, data ) => {
        return new Promise( done => {
            data.timezone = encodeURIComponent(Intl.DateTimeFormat().resolvedOptions().timeZone);
            axios.get( `/api/orders/profit/${data.startDate}/${data.endDate}/${data.timezone}` )
                .then( res => {
                    if( res.data && res.data.success ){
                        state.commit( 'setProfit', res.data.data );
                    }
                    done( res.data )
                } )
                .catch( err => done( err ) )
        } )
    },
    createOrder: (state, data) => {
        return new Promise( done => {
            axios.post( '/api/orders/create', data )
                .then( res => done( res.data ) )
                .catch( err => done( err ) )
        } )
    },
    getSingleOrder: (state, data) => {
        return new Promise( done => {
            axios.get( `/api/orders/single/${data.id}` )
            .then(res => {
                done( res.data )
            } )
            .catch( err => done( err ) )
        } )
    },
    getStats: (state, data ) => {
        return new Promise( done => {
            data.timezone = encodeURIComponent(Intl.DateTimeFormat().resolvedOptions().timeZone);
            axios.get( `/api/orders/stats/${data.startDate}/${data.endDate}/${data.timezone}?showPerHourStats=${data.showPerHourStats}&statsGamesSelected=${data.statsGamesSelected.join(',')}` )
                .then( res => {
                    if( res.data && res.data.success ){
                        state.commit( 'setStats', res.data.data );
                    }
                    done( res.data )
                } )
                .catch( err => done( err ) )
        } )
    },
    getStatsForExport: (state, data ) => {
        return new Promise( done => {
            data.timezone = encodeURIComponent(Intl.DateTimeFormat().resolvedOptions().timeZone);
            axios.get( `/api/orders/export/${data.startDate}/${data.endDate}/${data.timezone}` )
                .then( res => {
                    if( res.data && res.data.success ){
                        state.commit( 'setForExportStats', res.data.data );
                    }
                    done( res.data )
                } )
                .catch( err => done( err ) )
        } )
    },
    getImportantOrders: (state, data ) => {
        return new Promise( done => {
            axios.get( `/api/orders/list-important/${data.page}/${data.perPage}/?s=${data.searchTerm}` )
                .then( res => {
                    done( res.data )
                } )
                .catch( err => done( err ) )
        } )
    },
    getAllOrders: (state, data ) => {
        return new Promise( done => {
            axios.get( `/api/orders/list/${data.page}/${data.perPage}/${data.flag}/${data.hadReplacements}/${data.manualOrders}/?s=${data.searchTerm}` )
                .then( res => {
                    done( res.data )
                } )
                .catch( err => done( err ) )
        } )
    },
    deleteOrder: (state, data) => {
        return new Promise( done => {
            axios.delete( '/api/orders/delete/' + data.id )
                .then( res => done( res.data ) )
                .catch( err => done( err ) )
        } )
    },
    cancelOrder: (state, data) => {
        return new Promise( done => {
            axios.put( '/api/orders/cancel/', data )
                .then( res => done( res.data ) )
                .catch( err => done( err ) )
        } )
    },
    refundOrder: (state, data) => {
        return new Promise( done => {
            axios.put( '/api/orders/refund/', data )
                .then( res => done( res.data ) )
                .catch( err => done( err ) )
        } )
    },
    replaceAccount: (state, data) => {
        return new Promise( done => {
            axios.put( '/api/orders/replace/', data )
                .then( res => done( res.data ) )
                .catch( err => done( err ) )
        } )
    },
    setMeta: (state, data) => {
        return new Promise( done => {
            axios.put( '/api/orders/set-meta', data )
                .then( res => done( res.data ) )
                .catch( err => done( err ) )
        } )
    },
}