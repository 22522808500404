'use strict'

import axios from "axios";

if( !axios.defaults.headers.common.Authorization) {
    axios.defaults.headers.common = {
        "Authorization": "Basic " + localStorage.getItem( 'authbearer' ),
        "Content-Type": "application/json"
    };
}

export default {
    getG2GOffers: ( ) => {
        return new Promise( done => {
            axios.get( '/api/g2g-offer/list' )
            .then( res => {
                done( res.data )
            } )
            .catch( err => done( err ) )
        } )
    },
    getSingleG2GOffer: (state, data) => {
        return new Promise( done => {
            axios.get( '/api/g2g-offer/' + data.id )
            .then( res => {
                done( res.data )
            } )
            .catch( err => done( err ) )
        } )
    },
    duplicateG2GOffer: (state, data) => {
        return new Promise( done => {
            axios.put( '/api/g2g-offer/duplicate', data )
            .then( res => {
                done( res.data )
            } )
            .catch( err => done( err ) )
        } )
    },
    createASDAGameFromOffer: (state, data) => {
        return new Promise( done => {
            axios.put( '/api/g2g-offer/create-sda-game', data )
            .then( res => {
                done( res.data )
            } )
            .catch( err => done( err ) )
        } )
    },
    editG2GOffer: (state, data) => {
        return new Promise( done => {
            axios.put( '/api/g2g-offer/edit', data )
            .then( res => {
                done( res.data )
            } )
            .catch( err => done( err ) )
        } )
    },
    deleteG2GOffer: (state, data) => {
        return new Promise( done => {
            axios.delete( '/api/g2g-offer/' + data.id )
            .then( res => {
                done( res.data )
            } )
            .catch( err => done( err ) )
        } )
    },
    applyG2GOfferAction: (state, data) => {
        return new Promise( done => {
            axios.put( '/api/g2g-offer/set-action', data )
            .then( res => {
                done( res.data )
            } )
            .catch( err => done( err ) )
        } )
    },
    setG2GOfferMeta: (state, data) => {
        return new Promise( done => {
            axios.put( '/api/g2g-offer/set-meta', data )
                .then( res => done( res.data ) )
                .catch( err => done( err ) )
        } )
    }
}